import './navigation.scss';

class Navigation {
    constructor (element) {
        this.$navigation = element;

        this.$burgerMenu = this.$navigation.querySelector('.burger-menu');
        this.$body = document.querySelector('body');

        this.initialize();
    }

    initialize () {
        if (this.$navigation) {
            this.setEvents();
            this.resizeNavigation();
            this.scrollAnimateBurgerMenu();
            this.addMobileNavigationAnimation();
        }
    }

    resizeNavigation () {
        window.resizeHandler.customFunctions.push(() => {
            const windowWidth = window.innerWidth;
            if (windowWidth > 1023) {
                if (this.$burgerMenu.classList.contains('is--open')) {
                    this.$burgerMenu.click();
                }
            }
        });
    }

    setEvents () {
        if (this.$burgerMenu) {
            this.$burgerMenu.addEventListener('click', () => {
                this.$burgerMenu.classList.add('has--noevent');

                if (this.$burgerMenu.classList.contains('is--open')) {
                    this.$burgerMenu.classList.remove('is--open');
                    if (this.$navigation.classList.contains('has--sub-nav-open')) {
                        const $subnav = document.querySelector('.siteheader__sub-nav.is--active');
                        $subnav.classList.remove('is--active');
                        this.$body.classList.remove('no-scroll');
                        setTimeout(() => {
                            this.$navigation.classList.remove('has--mobile-menu-visible');
                            this.$navigation.classList.remove('has--sub-nav-open');
                            this.$navigation.classList.add('no--first-level-animation');
                        }, 500);
                        setTimeout(() => {
                            this.$navigation.classList.remove('no--first-level-animation');
                            this.$navigation.classList.remove('has--animated-header');
                            $subnav.classList.remove('is--hiding');
                        }, 1000);
                    } else {
                        this.$navigation.classList.remove('has--mobile-menu-visible');
                        this.$navigation.classList.add('is--hiding');
                        this.$navigation.classList.add('is--hiding-opacity');
                        this.$body.classList.remove('no-scroll');
                        setTimeout(() => {
                            this.$navigation.classList.remove('is--hiding-opacity');
                        }, 1100);
                        setTimeout(() => {
                            this.$navigation.classList.remove('is--hiding');
                            this.$navigation.classList.remove('has--animated-header');
                        }, 1100);
                    }
                } else {
                    this.$burgerMenu.classList.add('is--open');
                    this.$navigation.classList.add('has--mobile-menu-visible');
                    this.$body.classList.add('no-scroll');
                    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                    if (scrollTop > 20) {
                        this.$navigation.classList.add('has--animated-header');
                    }
                }

                setTimeout(() => {
                    this.$burgerMenu.classList.remove('has--noevent');
                }, 1100);
            });
        }

        const $menuItems = this.$navigation.querySelectorAll('.main-nav__list-item.has--children .main-nav__item');
        if ($menuItems.length > 0) {
            for (let i = 0; i < $menuItems.length; i++) {
                const $menuItem = $menuItems[i];

                $menuItem.addEventListener('click', (e) => {
                    const $subnav = $menuItem.parentElement.querySelector('.siteheader__sub-nav');
                    if ($subnav) {
                        if ($subnav.classList.contains('is--active')) {
                            this.closeSubNav($subnav, 'height');
                        } else {
                            this.openSubNav($subnav);
                        }
                    }
                    e.preventDefault();
                });
            }
        }

        const $subnavCloseButtons = this.$navigation.querySelectorAll('.siteheader__sub-nav-close');
        if ($subnavCloseButtons.length > 0) {
            for (let i = 0; i < $subnavCloseButtons.length; i++) {
                const $subnavCloseButton = $subnavCloseButtons[i];

                $subnavCloseButton.addEventListener('click', (e) => {
                    const $subnav = $subnavCloseButton.closest('.siteheader__sub-nav');
                    this.closeSubNav($subnav, 'height');
                });
            }
        }

        const $body = document.querySelector('body');
        $body.addEventListener('click', (e) => {
            if (window.innerWidth > 1023) {
                const $siteheader = document.querySelector('.siteheader');
                if ($siteheader.classList.contains('has--sub-nav-open')) {
                    const $clickedElement = e.target;
                    const checkParentSubnav = $clickedElement.closest('.siteheader__sub-nav');
                    if (!checkParentSubnav && !$clickedElement.classList.contains('main-nav__item')) {
                        const $subnav = document.querySelector('.siteheader__sub-nav.is--active');
                        if ($subnav) {
                            this.closeSubNav($subnav, 'height');
                        }
                    }
                }
            }
        });

        const $mobileMenuClose = document.querySelectorAll('.siteheader__sub-nav-header');
        if ($mobileMenuClose.length > 0) {
            for (let i = 0; i < $mobileMenuClose.length; i++) {
                const $closeButton = $mobileMenuClose[i];
                $closeButton.addEventListener('click', () => {
                    const $subnav = $closeButton.closest('.siteheader__sub-nav');
                    this.closeSubNav($subnav);
                });
            }
        }
    }

    scrollAnimateBurgerMenu () {
        if (this.$burgerMenu) {
            window.eventScroller.customFunctions.push(() => {
                const scrollDirection = window.eventScroller.getScrollInfo().direction;
                if (scrollDirection === 'up') {
                    this.$burgerMenu.classList.add('has--cirle-animation');
                } else {
                    this.$burgerMenu.classList.remove('has--cirle-animation');
                }
            });
        }
    }

    addMobileNavigationAnimation () {
        setTimeout(() => {
            this.$navigation.classList.add('is--visible');
        }, 2000);
    }

    openSubNav (subnav) {
        const $subnav = subnav;
        const $siteheader = document.querySelector('.siteheader');
        const windowWidth = window.innerWidth;
        if (windowWidth > 1023) {
            const $activeSubnav = document.querySelector('.siteheader__sub-nav.is--active');
            const $subnavInner = $subnav.querySelector('.siteheader__sub-nav-inner');
            const subnavHeight = $subnavInner.offsetHeight;
            $subnav.style.maxHeight = subnavHeight + 'px';
            setTimeout(() => {
                if ($activeSubnav) {
                    this.closeSubNav($activeSubnav, 'opacity');
                }
            }, 200);
            setTimeout(() => {
                $subnav.style.maxHeight = 'none';
            }, 500);
        } else {
            $subnav.classList.add('is--hiding');
        }
        $siteheader.classList.add('has--sub-nav-open');
        $subnav.classList.add('is--active');
    }

    closeSubNav (subnav, animation) {
        const $subnav = subnav;
        $subnav.classList.remove('is--active');
        const $siteheader = document.querySelector('.siteheader');
        $siteheader.classList.remove('has--sub-nav-open');
        const windowWidth = window.innerWidth;
        if (windowWidth > 1023) {
            if (animation) {
                if (animation === 'opacity') {
                    $subnav.classList.add('has--animation-opacity');

                    setTimeout(() => {
                        $subnav.classList.remove('has--animation-opacity');
                        $subnav.style.maxHeight = '0';
                    }, 250);
                } else {
                    const $subnavInner = $subnav.querySelector('.siteheader__sub-nav-inner');
                    const subnavHeight = $subnavInner.offsetHeight;
                    $subnav.style.maxHeight = subnavHeight + 'px';
                    setTimeout(() => {
                        $subnav.style.maxHeight = '0';
                    }, 100);
                }
            }
        } else {
            setTimeout(() => {
                $subnav.classList.remove('is--hiding');
            }, 500);
        }
    }
}

export { Navigation };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $navigation = $context.querySelector('.siteheader');
        if ($navigation) {
            const navigationAPI = new Navigation($navigation);
            $navigation.API = navigationAPI;
        }
    }
});
